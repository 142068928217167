import React from 'react';
import '../styles/EPK.css';
import NewLogo from '../assets/new_logo.png';

function EPK() {
  return (
    <div className='epk'>
      <img src={NewLogo} alt="logo" />












    </div>
  )
}

export default EPK