import './App.css';
import Home from './pages/Home'
import Gallery from './pages/Gallery';
import Music from './pages/Music';
import Shows from './pages/Shows';
import About from './pages/About';
import Contact from './pages/Contact';
import EPK from './pages/EPK';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="App">
      
      <BrowserRouter>
        
        <ScrollToTop />
        <Navbar />
        
        <Routes>
          <Route path='/' element={<Home />} />
          <Route exact path='/gallery' element={<Gallery />} />
          <Route exact path='/music' element={<Music />} />
          <Route exact path='/shows' element={<Shows />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/contact' element={<Contact />} />
        </Routes>

      </BrowserRouter>

      <Footer />

    </div>
  );
}

export default App;