import React from 'react';
import Logo from '../assets/cwr_logo.png';
import {Link} from 'react-router-dom';
import '../styles/component_styles/Navbar.css';
import DropdownMenu from './DropdownMenu';

function Navbar() {
  return (
    <div className='navbar'>
        <div className='left-side'>
          <a href="/">
            <img src={Logo} alt='Super Cool Logo' />
          </a>
        </div>
        
        <div className='right-side'>
          <div className="dropdown-component">
            <DropdownMenu />
          </div>
          
          <div className="linky-links">
            <Link to='/music'>Music</Link>
            <Link to='/gallery'>Gallery</Link>
            <Link to='/shows'>Shows</Link>
            <Link to='/about'>About</Link>
            <Link to='/contact'>Contact</Link>
          </div>
        </div>

    </div>
  );
}

export default Navbar;