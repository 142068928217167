import React from 'react';
import '../styles/Shows.css'

function Shows() {
  return (
    <div className='shows'>
      <h1 className='page-title'>Shows</h1>
        
      <div className='shows-table'>
          {/* section tags contain one show each */}
          
          {/* <section>
            <div className="no-show">
              <h1>No shows at the moment.</h1>
              <h3>Don't worry, they're lining up! </h3>
            </div>
          </section> */}
          
          <section>
            <div className='circle'>
              <div className='date'>
                <p className='month'>Sept</p>
                <p className='day'>23</p>
                <p className='year'>2023</p>
              </div>
            </div>
            
            <div className='venue'>
              <h1>Vallensons' @ 6pm</h1>
              <h4>Pearland, TX</h4>
            </div>
            
            <div className='buttons'>
              <button className='info'>
                <a target='_blank' href="https://fb.me/e/3QBF1SQWh">Info</a>
              </button>
            </div>

            <hr />
          </section>

          <section>
            <div className='circle'>
              <div className='date'>
                <p className='month'>Oct</p>
                <p className='day'>12</p>
                <p className='year'>2023</p>
              </div>
            </div>
            
            <div className='venue'>
              <h1>Grazia @ 6pm</h1>
              <h4>Pearland, TX</h4>
            </div>
            
            <div className='buttons'>
              <button className='info'>
                <a href="https://www.facebook.com/events/3458554401126178/">Info</a>
              </button>
            </div>

            <hr />
          </section>

          {/* <section>
            <div className='circle'>
              <div className='date'>
                <p className='month'>July</p>
                <p className='day'>11</p>
                <p className='year'>2023</p>
              </div>
            </div>
            
            <div className='venue'>
              <h1>Venue @ 7:11pm</h1>
              <h4>Houston, TX</h4>
            </div>
            
            <div className='buttons'>
              <button className='info'>
                <a href="http://localhost:3000/shows">Info</a>
              </button>
            </div>

            <hr />
          </section> */}
          
          
          
      </div>
    </div>
  );
}

export default Shows;